<template>
  <div class="order-page">
    <div class="addressContent orderAddress"
    :class="{ 'no-address': !addressList }">
      <div v-if="addressList">
        <div class="line1">
          <div class="name">{{ addressList.receiptname }}</div>
          <div class="tel">{{ addressList.call }}</div>
        </div>
        <div class="line2" v-html="addressActioned(addressList)"></div>
        <!-- <div class="orderAddressArrow">
          <img src="./img/arrow.png">
        </div> -->
      </div>
    </div>
    <div class="orderContent">
      <div class="order-imgs">
        <div class="orderImg">
					<img :src="thumbUrl">
				</div>
				<div class="orderText">
					<p class="orderYY">[有鱼定制]</p>
					<p class="orderTitle">{{ goodsDetails.goodsName }}</p>
				</div>
        <div class="detailContent">
          <p>
            <span>
              价格:
              <span class="originPrice">{{ goodsDetails.goldPrice }}</span>金豆
            </span>
            <span> 市场价 <span class="originPrice">{{ goodsDetails.originPrice }}</span>元</span>
          </p>
        </div>
        <div class="detailContent detailscotent1">
          <p>
            <span>
             订单号: {{ gerId }}
            </span>
            <span> </span>
          </p>
        </div>
        <div class="detailContent detailContent2">
          <p>
            <span class="dan">
             运单号: {{ wayId }}
            </span>
            <span class="dan" v-if="wayId"><span class="way-btn" @click="copy">复制</span></span>
          </p>
          <p>
            <span>
             物流名称: {{ companyCode }}
            </span></p>
        </div>
      </div>
		</div>
  </div>
</template>
<script>
import { getAppStatus, callAppHandler } from '@/utils/app';
import { getQueryGoodsDetail } from '@/api/base';

export default {
  name: 'GoldNewOrder',
  data() {
    return {
      thumbUrl: '',
      isLoading: true,
      goodsDetails: {},
      addressList: {},
      cuserid: getAppStatus().cuserId,
    };
  },
  computed: {
    cuserids() {
      // return '000dfea3-8605-4c06-ac67-bfbfad76ad62';
      return getAppStatus().cuserId;
    },
    from() {
      const { from } = this.$route.query;
      return from;
    },
    gerId() { // 订单号
      const { gerId } = this.$route.query;
      return gerId;
    },
    prizeId() {
      const { prizeId } = this.$route.query;
      return prizeId;
    },
    mid() {
      const { mid } = this.$route.query;
      return mid;
    },
    goodsId() {
      const { goodsId } = this.$route.query;
      return goodsId;
    },
    wayId() { // 运单号
      const { wayId } = this.$route.query;
      return wayId;
    },
    companyCode() { // 物流名称
      const { companyCode } = this.$route.query;
      return companyCode;
    },
  },
  mounted() {
    document.title = '订单详情';
    this.cuserId = this.cuserId || sessionStorage.getItem('userId');
    this.cuserid && this.initData();
    if (!this.cuserid) {
      callAppHandler('jzUserId', null, (res) => {
        sessionStorage.setItem('userId', res);
        this.cuserid = res;
        this.initData();
      });
    }
  },
  methods: {
    initData() {
      sessionStorage.setItem('userId', this.cuserid);
      const { thumbUrl } = this.$route.query;
      this.thumbUrl = thumbUrl;
      let addressList = sessionStorage.getItem('addressList');
      this.addressList = JSON.parse(addressList);
      this.getDetailsData();
      console.log(this.$route.query, 'query----');
    },
    addressActioned(data) {
      if (data.address) {
        let arr = data.address.split('/');
        return arr[0] + '<br/>' + arr[1];
      }
      return '';
    },
    async getDetailsData() {
      let res;
      const { goodsId, cuserid } = this;
      try {
        res = await getQueryGoodsDetail({ goodsId, cuserid });
      } catch (e) {
        console.log(e);
        return this.$toast({
          content: e.desc,
          duration: 3000,
        });
      }
      if (res.code === 1) {
        this.goodsDetails = res.results;
        if (!this.thumbUrl) {
          this.thumbUrl = res.results.goodsPicUrl;
        }
      }
    },
    jumpRecord() {
      this.$router.push({
        path: 'record',
      });
    },
    copy() {
      let input = document.createElement('input');
      input.value = this.wayId;
      document.body.appendChild(input);
      input.select();
      // input.setSelectionRange(0, input.value.length);
      document.execCommand('Copy');
      document.body.removeChild(input);
      this.$toast({
        content: '已复制',
        duration: 1000,
      });
    },
  },
  destroyed() {
  },
};
</script>
<style scoped lang="scss">
  .order-page {
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    position: relative;
    background-color: #f6f6f6;
  }
  .orderAddress {
    background: #ffffff;
    border-bottom: solid 1px #dddddd;
    padding: 0.533333rem 0.8rem 0.693333rem 1.226666rem;
    min-height: 0;
  }
  .orderAddress.no-address::before {
    display: none;
  }
  .addressContent:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0.266666rem;
    transform: translateY(-50%);
    display: inline-block;
    vertical-align: middle;
    width: 0.557rem;
    height: 0.59rem;
    background-size: 100% 100%;
  }
.addressContent {
  // width: 100%;
  // min-height: 2.666666rem;
  // padding: 0.370666rem 0.4rem 0.266666rem 1.226666rem;
  // box-sizing: border-box;
  // -webkit-box-sizing: border-box;
  // -moz-box-sizing: border-box;
  background-color: #fff;
  margin: 0 0.21333rem;
  border-radius: 0.32rem;
  position: relative;
}
  // .orderAddress:before {
  //   background-image: url(./img/position.png);
  //   width: 0.44rem;
  //   height: 0.56rem;
  //   left: 0.4rem !important;
  // }
/*.orderAddress:after*/
.orderAddressArrow {
  /*content: "";*/
  width: 0.213333rem;
  height: 0.373333rem;
  // background-image: url('./img/arrow.png');
  background-size: cover;
  right: 0.4rem;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  img {
    width: 100%;
  }
}
.orderContent {
  background: #ffffff;
  font-size: 0;
  padding: 0.533333rem 0;
  margin: 0.21333rem 0.21333rem;
  border-radius: 0.32rem;
  /* border-bottom: solid 1px #dddddd; */
}
.orderImg {
  display: inline-block;
  vertical-align: middle;
  width: 1.6rem;
  margin-right: 0.266666rem;
}
.orderImg > img {
  width: 100%;
  height: auto;
  display: block;
}
.orderTip {
  padding-left: 0.4rem;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin-top: 0.266666rem;
  font-size: 0;
}
.orderTipImg {
  display: inline-block;
  vertical-align: top;
  line-height: 1.2;
  /*width: 0.426666rem;*/
  width: 4.4%;
  margin-right: 2.8%;
  /*height: 0.373333rem;*/
  /*margin-right: 0.266666rem;*/
  /*background-image:url(../img/orderTip.png);*/
  /*background-size: cover;*/
}
.orderTipImg > img {
  width: 100%;
  height: auto;
  display: block;
}
.orderTip > span {
  display: inline-block;
  vertical-align: top;
  line-height: 1.2;
  padding-right: 0.266666rem;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 92.8%;
  font-size: 0.346666rem;
  letter-spacing: -0.3px;
  color: #999999;
}
.line1 {
  overflow: hidden;
  margin-bottom: 0.266666rem;
}
.name {
  /*float: left;*/
  display: inline-block;
  vertical-align: middle;
  font-size: 0.426666rem;
  letter-spacing: -0.4px;
  color: #333333;
  margin-right: 0.8rem;
}
.tel {
  display: inline-block;
  vertical-align: middle;
  /*float: right;*/
  font-size: 0.426666rem;
  letter-spacing: -0.4px;
  color: #333333;
}
.line2 {
  font-size: 0.426666rem;
  letter-spacing: -0.4px;
  color: #333333;
  padding-right: 0.266666rem;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.orderContainer .line2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.operate {
  height: 1.066666rem;
  padding: 0 0.4rem;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border-top: solid 1px #dddddd;
  overflow: hidden;
}
.btn {
  width: 80%;
  height: 1.17333rem;
  background: linear-gradient(95deg,#ff4a4a,#ff3636);
  border-radius: .58667rem;
  font-size: .45333rem;
  font-weight: 500;
  color: #fff;
  line-height: 1.17333rem;
  text-align: center;
  margin: 60px auto 0;
  &.default {
    background: rgba(0, 0, 0, .3);
  }
}
.orderText {
  display: inline-block;
  vertical-align: middle;
}
.orderYY {
  font-size: 0.32rem;
  font-weight: 500;
  color: #333333;
  margin-bottom: 0.053333rem;
}
.orderTitle {
  font-size: 0.4rem;
  color: #333333;
}
.detailContent {
  margin: 0 0.3rem;
  &:last-child {
    margin-top: 15px;
  }
  &:nth-child(2) {
    p > span {
      width: 35%;
    }
  }
}
.detailContent > p {
  font-size: 0;
  margin-bottom: 0.106666rem;
}
.detailContent {
  margin-top: .2rem;
}
.detailContent.detailscotent1 > p > span {
  width: auto;
}
.detailContent > p:last-child {
  margin-bottom: 0;
}
.detailContent > p > span {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
  font-size: 0.346666rem;
  color: #999999;
  &.companyCode {
    float: right;
    width: auto;
  }
}
.detailContent > p > span:nth-of-type(2) {
  text-align: right;
}
.detailContent > p > span > span {
  font-size: 0.426666rem;
  color: #ff3636;
  font-weight: 500;
}
.address-write {
  text-align: center;
  font-size: .4rem;
  position: relative;
}
.way-btn {
  display: inline-block;
  width: 90px;
  height: 40px;
  font-size: 20px !important;
  font-weight: normal !important;
  text-align: center;
  line-height: 40px;
  border: 1PX solid #ff3636;
  border-radius: 8px;
  position: relative;
  top: -.03rem;
  margin-left: 10px;
  float: right;
}
.detailContent.detailContent2 > p {
  // overflow: hidden;
  height: 0.5333rem;
}
.detailContent.detailContent2 > p > span.dan {
  float: left;
  width: auto;
  &:nth-child(2) {
    padding-left: 15px;
    float: right;
  }
}
</style>
